import { Injectable } from '@angular/core';
import {
  ForgotConfirmDto,
  ForgotDto,
  OwnersApproversResponseDto,
  UserResponseWithPermissionsDto,
} from 'shared-general-libs/dto/user';
import {
  requestLoading,
  RequestLoadingType,
} from '@ieCore/helpers/request-loading.helper';
import { UserRepository } from '@ieCore/repositories/user.repository';
import { map, take } from 'rxjs/operators';
import { selectUser } from '@ieApp/store/user/user.selectors';
import { filter, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import {
  UserResponseDto,
  CreateUserDto,
  UpdateUserDto,
} from 'shared-general-libs/dto/user';
import { UsersRequestParams } from '@ieShared/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private userRepository: UserRepository,
    private store: Store,
  ) {}

  getMe(): RequestLoadingType<UserResponseWithPermissionsDto> {
    return requestLoading(this.userRepository.getMe().pipe(map((res) => res)));
  }

  forgotPassword(payload: ForgotDto): RequestLoadingType<void> {
    return requestLoading(
      this.userRepository.forgotPassword(payload).pipe(map((res) => res)),
    );
  }

  confirmForgotPassword(payload: ForgotConfirmDto): RequestLoadingType<void> {
    return requestLoading(
      this.userRepository
        .confirmForgotPassword(payload)
        .pipe(map((res) => res)),
    );
  }

  async hasPermission(
    permission: string | string[] | undefined,
    requireAllPermissions: boolean = false,
  ): Promise<boolean> {
    if (Array.isArray(permission) && permission.length === 0) {
      return true;
    } else if (!permission) {
      return true;
    }

    const user = await firstValueFrom(
      this.store.select(selectUser).pipe(
        filter((userState) => userState !== null),
        take(1),
      ),
    );

    if (!user) return false;

    if (Array.isArray(permission)) {
      if (requireAllPermissions) {
        return permission.every((p) => user!.permissions.includes(p));
      } else {
        return permission.some((p) => user!.permissions.includes(p));
      }
    } else {
      return user!.permissions.includes(permission);
    }
  }

  getUser(id: string): RequestLoadingType<UserResponseWithPermissionsDto> {
    return requestLoading(
      this.userRepository.getUser(id).pipe(map((res) => res)),
    );
  }

  getUsers(
    params: UsersRequestParams,
  ): RequestLoadingType<PaginatedResponseDto<UserResponseDto>> {
    return requestLoading(this.userRepository.getUsers(params));
  }

  createUser(payload: CreateUserDto): RequestLoadingType<UserResponseDto> {
    return requestLoading(this.userRepository.createUser(payload));
  }

  editUser(
    id: string,
    payload: UpdateUserDto,
  ): RequestLoadingType<UserResponseDto> {
    return requestLoading(this.userRepository.updateUser(id, payload));
  }

  getOwnersApprovers(): RequestLoadingType<OwnersApproversResponseDto> {
    return requestLoading(this.userRepository.getOwnersApprovers());
  }
}
