import { Injectable } from '@angular/core';
import { environment } from '@ieEnv/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateUserDto,
  ForgotConfirmDto,
  ForgotDto,
  OwnersApproversResponseDto,
  UpdateUserDto,
  UserResponseDto,
  UserResponseWithPermissionsDto,
} from 'shared-general-libs/dto/user';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import { UsersRequestParams } from '@ieShared/interfaces/user.interface';
import { removeEmptyFields } from '@ieShared/utils/query.utils';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private readonly apiUrl: string = environment.userHost;

  constructor(private http: HttpClient) {}

  getMe(): Observable<UserResponseWithPermissionsDto> {
    return this.http.get<UserResponseWithPermissionsDto>(
      `${this.apiUrl}/users/me`,
      {},
    );
  }

  getUser(userId: string): Observable<UserResponseWithPermissionsDto> {
    return this.http.get<UserResponseWithPermissionsDto>(
      `${this.apiUrl}/users/${userId}`,
      {},
    );
  }

  forgotPassword({ email }: ForgotDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/users/forgot`, {
      email,
    });
  }

  confirmForgotPassword({
    token,
    password,
  }: ForgotConfirmDto): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/users/forgot-confirm`, {
      token,
      password,
    });
  }

  getUsers(
    params: UsersRequestParams,
  ): Observable<PaginatedResponseDto<UserResponseDto>> {
    return this.http.post<PaginatedResponseDto<UserResponseDto>>(
      `${this.apiUrl}/users/search`,
      removeEmptyFields(params),
    );
  }

  createUser(payload: CreateUserDto): Observable<UserResponseDto> {
    return this.http.post<UserResponseDto>(`${this.apiUrl}/users`, payload);
  }

  updateUser(id: string, payload: UpdateUserDto): Observable<UserResponseDto> {
    return this.http.put<UserResponseDto>(
      `${this.apiUrl}/users/${id}`,
      payload,
    );
  }

  getOwnersApprovers(): Observable<OwnersApproversResponseDto> {
    return this.http.get<OwnersApproversResponseDto>(
      `${this.apiUrl}/users/owners-approvers`,
    );
  }
}
