<button
  [type]="type"
  class="flex justify-center items-center si-button si-button--{{
    buttonType
  }} si-button--{{ size }}"
  [ngClass]="{ 'f-width': fullWidth, 'si-button--only-icon': onlyIcon }"
  [disabled]="loading || disabled"
>
  @if (loading) {
    <ie-icons
      class="loading-icon"
      [ngClass]="{ 'mr-8': ref.innerHTML.trim() !== '' }"
      icon="loading"
      [width]="16"
      [height]="16"
    ></ie-icons>
  } @else if (icon) {
    <ie-icons
      [ngClass]="{ 'mr-8': ref.innerHTML.trim() !== '' }"
      [icon]="icon"
      [width]="18"
      [height]="18"
    ></ie-icons>
  }
  <span #ref>
    <ng-content></ng-content>
  </span>
</button>
