import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';
import { APP_STORE } from '@ieCore/consts/store.consts';

export const selectUserState = createFeatureSelector<UserState>(APP_STORE.USER);

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user,
);

export const selectUserId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.id,
);

export const selectUserOrganizationId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.orgId,
);
