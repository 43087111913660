import { Component, Input } from '@angular/core';

@Component({
  selector: 'ie-card',
  standalone: true,
  imports: [],
  templateUrl: './ie-card.component.html',
  styleUrl: './ie-card.component.scss',
})
export class IeCardComponent {
  @Input() headerSize: 'default' | 'auto' = 'default';
}
