import { Observable, BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

export type RequestLoadingType<T> = {
  isLoading: BehaviorSubject<boolean>;
  isSucceed: BehaviorSubject<boolean | null>;
  response: Observable<T>;
  cancel: () => void;
};

export function requestLoading<T>(
  source?: Observable<T> | undefined,
): RequestLoadingType<T> {
  const isLoading = new BehaviorSubject<boolean>(false);
  const isSucceed = new BehaviorSubject<boolean | null>(null);
  const cancelNotifier = new Subject<void>();

  if (source === undefined) {
    return {
      isLoading,
      isSucceed,
      response: new Observable<T>(),
      cancel: () => cancelNotifier.next(),
    };
  }

  isLoading.next(true);
  const response = new Observable<T>((observer) => {
    source
      .pipe(
        tap(() => {
          isLoading.next(true);
          isSucceed.next(null);
        }),
        finalize(() => isLoading.next(false)),
        takeUntil(cancelNotifier),
      )
      .subscribe({
        next(value) {
          observer.next(value);
          isSucceed.next(true);
        },
        error(err) {
          observer.error(err);
          isSucceed.next(false);
        },
        complete() {
          observer.complete();
        },
      });
  });

  return {
    isLoading,
    isSucceed,
    response,
    cancel: () => cancelNotifier.next(),
  };
}
