<div class="header {{ headerSize }}">
  <ng-content select="[card-title]"></ng-content>
</div>

<div class="body">
  <ng-content select="[card-body]"></ng-content>
</div>

<div class="footer">
  <ng-content select="[card-footer]"></ng-content>
</div>
