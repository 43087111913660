type QueryParams = {
  [key: string]: string | number | boolean | (string | number | boolean)[];
};

export function removeEmptyFields<T extends object>(obj: T): QueryParams {
  return Object.keys(obj).reduce<QueryParams>((acc, key) => {
    const value = obj[key as keyof T];
    if (value !== undefined && value !== null && value !== '') {
      (acc as T)[key as keyof T] = value;
    }
    return acc;
  }, {});
}
